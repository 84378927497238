import { useContext, useEffect } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';

import {
    BUSINESS_UNIT_ADROLL, // @ts-ignore - no types for ar-style-base
} from '@nextroll/ar-style-base';

import Footer from '../../components/Footer';
import Header from '../../components/Header';
import ThemeWrapperContext from '../../contexts/ThemeWrapperContext';
import SignUpForm from '../../forms/sign-up/SignUpForm';
import { getTreatment, loadSplitIo } from '../../utils/ab';
import {
    getFromLocalStorage,
    setToLocalStorage,
} from '../../utils/localstorage';

const SPLITIO_SIGNUP_EXP_R1 = 'nwa-signup-experiments-r1';
const LS_KEY = `ab-anon-user-v1`;

const SignUpPage = () => {
    const navigate = useNavigate();
    const search = useLocation().search;
    const { businessUnit } = useContext(ThemeWrapperContext);

    useEffect(() => {
        if (window.Cypress) {
            console.log('Cypress detected, skipping AB test');
            return;
        }
        if (businessUnit !== BUSINESS_UNIT_ADROLL) {
            return;
        }
        let userId = getFromLocalStorage(LS_KEY);
        if (!userId) {
            userId = `anon-user-b${Math.floor(Math.random() * 100)}`;
            setToLocalStorage(LS_KEY, userId);
        }

        loadSplitIo(userId, () => {
            const t = getTreatment(SPLITIO_SIGNUP_EXP_R1);
            // If control or off, we fallback. If on, this is already the right variant.
            if (t === 'control' || t === 'off' || t === 'on') {
                return;
            }

            navigate(`../${t}${search}`);
        });
    }, []);

    return (
        <section className='entryhall-page-sign-up'>
            <Header />
            <SignUpForm />
            <Footer />
        </section>
    );
};

export default SignUpPage;
