import classNames from 'classnames';
import { useContext } from 'react';

import i18n from '@nextroll/ar-i18n';
// @ts-ignore - no types for ar-style-base
import {
    BUSINESS_UNIT_ROLLWORKS,
    Option,
    Select, // @ts-ignore - no types for ar-style-base
} from '@nextroll/ar-style-base';

import {
    setFieldFactory,
    setShowFieldErrorFactory,
} from '../../../actions/sign-up/SignUpActions';
import { NORTH_AMERICAN_COUNTRIES } from '../../../constants';
import ThemeWrapperContext from '../../../contexts/ThemeWrapperContext';
import SignUpContext from '../../../contexts/sign-up/SignUpContext';
import { ERROR_MESSAGES } from '../../../errors';

// These are not a constant because of i18n.
export const getOptions = (): { code: string; displayName: string }[] => {
    let countryList = Object.entries(i18n.COUNTRY_CODES).map(([key]) => ({
        code: key,
        displayName: i18n.getCountryName(key),
    }));

    const usca = countryList.filter((x) => x.code === 'US' || x.code === 'CA');
    countryList = countryList.filter((x) => x.code !== 'US' && x.code !== 'CA');

    countryList.sort((a, b) => (a.displayName > b.displayName ? 1 : -1));
    countryList.unshift(...usca);

    return countryList;
};

// TODO: restore `required` decorator
export const validate = (value: string, businessUnit: string) => {
    if (value === '') {
        return i18n.gettext(ERROR_MESSAGES.required_field);
    }
    if (
        businessUnit === BUSINESS_UNIT_ROLLWORKS &&
        !NORTH_AMERICAN_COUNTRIES.includes(value)
    ) {
        return i18n.gettext(ERROR_MESSAGES.rollworks_country_not_allowed);
    }
    return null;
};

export const searchCallback = (search: string, optionValue: string) =>
    i18n
        .getCountryName(optionValue)
        .toLowerCase()
        .includes(search.toLowerCase());

const CountryCode = () => {
    const { state, dispatch } = useContext(SignUpContext);
    const { businessUnit } = useContext(ThemeWrapperContext);

    const field = state.get('fields').countryCode;
    const setField = setFieldFactory(dispatch, 'countryCode');
    const onChange = (value: string) => {
        const err = validate(value, businessUnit);
        setField(value, err === null, err);
        if (value !== field.value) {
            setFieldFactory(dispatch, 'region')(
                '',
                NORTH_AMERICAN_COUNTRIES.includes(value) === false,
                null
            );
        }
    };
    const setShowFieldError = setShowFieldErrorFactory(dispatch, 'countryCode');
    // This is calculated on-the-fly because we cannot trigger an onChange from the onClose (which is not
    // feasible because we don't have access to the new value yet)
    const error = validate(field.value, businessUnit);

    return (
        <div
            className={classNames('form-group entryhall-select', {
                'has-error': field.showError && error,
                'no-selected': field.value === '',
            })}
        >
            <div className='entryhall-input-field-wrapper has-feedback has-feedback-right'>
                <Select
                    i18n={i18n}
                    className='entryhall-input-country-code'
                    placeholder={i18n.gettext('Company Country')}
                    showSearch={true}
                    searchCallback={searchCallback}
                    onOptionSelect={onChange}
                    onOpen={() => setShowFieldError(false)}
                    onClose={() => setShowFieldError(true)}
                >
                    {getOptions().map((opt) => (
                        <Option
                            key={opt.code}
                            value={opt.code}
                            selected={opt.code === field.value}
                        >
                            {i18n.gettext(opt.displayName)}
                        </Option>
                    ))}
                </Select>
                <i className='form-control-feedback fa fa-globe-americas' />
            </div>
            {field.showError && error && (
                <span className='help-block ar-input-group-help'>{error}</span>
            )}
        </div>
    );
};

export default CountryCode;
