import { jwtDecode } from 'jwt-decode';
import { FormEvent, useContext, useEffect, useReducer, useState } from 'react';
import { useGoogleReCaptcha } from 'react-google-recaptcha-v3';
import { useLocation, useNavigate } from 'react-router-dom';

import i18n from '@nextroll/ar-i18n';

import {
    initFormState,
    setFieldFactory,
    setGoogleIdTokenFactory,
    setReCaptchaFactory,
    submitFactory,
} from '../../actions/sign-up/SignUpActions';
import FormSpinner from '../../components/FormSpinner';
import GoogleButton, {
    CredentialResponse,
    IIdInfo,
} from '../../components/GoogleButton';
import InlineFeedback from '../../components/InlineFeedback';
import { ApiError } from '../../components/errors';
import Title from '../../components/sign-up/Title';
import ToS from '../../components/sign-up/ToS';
import { API_STATUSES } from '../../constants';
import ThemeWrapperContext from '../../contexts/ThemeWrapperContext';
import SignUpContext, {
    INITIAL_SIGN_UP_FORM_STATE,
} from '../../contexts/sign-up/SignUpContext';
import SignUpReducer from '../../reducers/sign-up/SignUpReducer';
import { areFieldsValid } from '../../utils/sign-up/fields';
import {
    loadMarketingIntegrations,
    removeMarketingIntegrations,
} from '../../utils/sign-up/marketing';
import CompanyMarket from './fields/CompanyMarket';
import CompanyPhone from './fields/CompanyPhone';
import CountryCode from './fields/CountryCode';
import Email, { validate as validateEmail } from './fields/Email';
import FirstName, { validate as validateFirstName } from './fields/FirstName';
import LastName, { validate as validateLastName } from './fields/LastName';
import Password from './fields/Password';
import Region from './fields/Region';
import Url, { validate as validateUrl } from './fields/Url';

export const _SignUpForm = () => {
    const { state, dispatch } = useContext(SignUpContext);
    const { businessUnit, supportEmail } = useContext(ThemeWrapperContext);
    const [redirectSpinner, setRedirectSpinner] = useState(false);

    const navigate = useNavigate();
    const navigateToSignIn = () => navigate('../signin');
    const search = useLocation().search;

    const fields = state.get('fields');
    const extraFields = state.get('extraFields');
    const reCaptcha = state.get('reCaptcha');
    const googleIdToken = state.get('googleIdToken');
    const submitState = state.get('submit');

    const isGSignUp = googleIdToken !== '';

    const disabled = areFieldsValid(fields) === false;

    useEffect(() => {
        const params = new URLSearchParams(search);
        initFormState(dispatch, params);

        if (params.get('source') === 'shopify_2022_03') {
            setRedirectSpinner(true);
            window.location.href = `/welcome/shopify-signup${search}`;
        }
    }, []);

    useEffect(() => {
        if (submitState.status === API_STATUSES.SUCCESS) {
            if (submitState.redirect === 'internal_redirect') {
                navigate(`../${submitState.to}`);
            } else if (submitState.redirect === 'external_redirect') {
                setRedirectSpinner(true);
                window.location.href = submitState.to;
            }
        }
    }, [submitState]);

    const setReCaptcha = setReCaptchaFactory(
        dispatch,
        useGoogleReCaptcha().executeRecaptcha
    );
    const setGoogleToken = setGoogleIdTokenFactory(dispatch);
    const setEmail = setFieldFactory(dispatch, 'email');
    const setFirstName = setFieldFactory(dispatch, 'firstName');
    const setLastName = setFieldFactory(dispatch, 'lastName');
    const setUrl = setFieldFactory(dispatch, 'url');
    const setPassword = setFieldFactory(dispatch, 'password');

    const submit = submitFactory(
        dispatch,
        fields,
        businessUnit,
        googleIdToken,
        extraFields,
        {
            ...reCaptcha,
            executeReCaptcha: setReCaptcha,
        }
    );

    const onGoogleSuccess = (response: CredentialResponse) => {
        console.log('Google response:', response);
        setGoogleToken(response.credential);
        const idInfo: IIdInfo = jwtDecode(response.credential);
        console.log('Google idInfo:', idInfo);
        const emailErr = validateEmail(idInfo.email);
        setEmail(idInfo.email, emailErr === null, emailErr);
        const firstNameErr = validateFirstName(idInfo.given_name);
        setFirstName(idInfo.given_name, firstNameErr === null, firstNameErr);
        const lastNameErr = validateLastName(idInfo.family_name);
        setLastName(idInfo.family_name, lastNameErr === null, lastNameErr);
        const url = idInfo.hd || '';
        const urlErr = validateUrl(url);
        setUrl(url, urlErr === null, urlErr);

        setPassword('', true, null); // Set 'valid' empty password
    };

    const onSubmit =
        !disabled && submitState.status !== API_STATUSES.IN_PROGRESS
            ? submit
            : undefined;
    const onFormSubmit = (e: FormEvent) => {
        e.preventDefault();
        onSubmit && onSubmit();
        return false;
    };

    if (redirectSpinner) {
        return <FormSpinner />;
    }
    return (
        <div className='entryhall-card-block entryhall-form-sign-up'>
            <Title isGoogleSignUp={isGSignUp} />
            <form id='registerForm' onSubmit={onFormSubmit}>
                <div className='entryhall-card-header'>
                    <div className='entryhall-card-header-buttons'>
                        {process.env.ENVIRONMENT !== 'production' &&
                            (isGSignUp ? (
                                <p className='entryhall-google-signup-g-info'>
                                    {i18n.gettext(
                                        "We've filled out your account information from your Google sign-in. We need a few more details to complete your account."
                                    )}
                                </p>
                            ) : (
                                <>
                                    <div className='entryhall-google-signup'>
                                        <GoogleButton
                                            onSuccess={onGoogleSuccess}
                                            onError={() => {
                                                // @FIXME implement error handling
                                                console.log(
                                                    'Error from GoogleButton'
                                                );
                                            }}
                                            text='signup_with'
                                            width={400}
                                        />
                                    </div>
                                    <div className='entryhall-or-separator'>
                                        <span>{i18n.gettext('Or')}</span>
                                    </div>
                                </>
                            ))}
                    </div>
                    <div className='entryhall-card-header-error'>
                        {submitState.showError && (
                            <ApiError
                                error={submitState.error}
                                supportEmailAddress={supportEmail}
                            />
                        )}
                    </div>
                </div>
                <div className='entryhall-card-body'>
                    <div className='entryhall-signup-field-names'>
                        <FirstName />
                        <LastName />
                    </div>
                    <Email />
                    <CompanyPhone />
                    <Url />
                    <CompanyMarket />
                    <CountryCode />
                    <Region />
                    <Password />
                </div>
                <div className='entryhall-card-footer'>
                    <ToS />
                    <button
                        type='submit'
                        id='ar-signup-button'
                        className='btn btn-primary btn-block'
                        disabled={disabled}
                    >
                        {i18n.gettext('SIGN UP')}
                        <InlineFeedback status={submitState.status} />
                    </button>
                    <section className='extra-info'>
                        <span>{i18n.gettext('Already have an account?')}</span>
                        <a onClick={navigateToSignIn}>
                            {i18n.gettext('Sign In')}
                        </a>
                    </section>
                </div>
            </form>
        </div>
    );
};

// SignUpForm is a wrapper around _SignUpForm that provides the context.
// This works differently from other forms, to allow using this form in various entry-hall pages
// without having to pass to configure the context in each page.
const SignUpForm = () => {
    const [state, dispatch] = useReducer(
        SignUpReducer,
        INITIAL_SIGN_UP_FORM_STATE
    );

    useEffect(() => {
        loadMarketingIntegrations();
        return () => {
            removeMarketingIntegrations();
        };
    }, []);

    return (
        <SignUpContext.Provider value={{ state, dispatch }}>
            <_SignUpForm />
        </SignUpContext.Provider>
    );
};

export default SignUpForm;
