import { FormEvent, useContext, useEffect, useState } from 'react';
import { useGoogleReCaptcha } from 'react-google-recaptcha-v3';
import { useLocation, useNavigate, useSearchParams } from 'react-router-dom';

import i18n from '@nextroll/ar-i18n';
// @ts-ignore - no types for ar-password-input
import PasswordInput from '@nextroll/ar-password-input';
// @ts-ignore - no types for ar-style-base
import { InputGroup } from '@nextroll/ar-style-base';

import {
    googleSubmitFactory,
    setEmailFactory,
    setNextFactory,
    setPasswordFactory,
    setReCaptchaFactory,
    submitFactory,
} from '../../actions/sign-in/SignInActions';
import FormSpinner from '../../components/FormSpinner';
import GoogleButton, {
    CredentialResponse,
} from '../../components/GoogleButton';
import InlineFeedback from '../../components/InlineFeedback';
import { ApiError } from '../../components/errors';
import { API_STATUSES } from '../../constants';
import ThemeWrapperContext from '../../contexts/ThemeWrapperContext';
import SignInContext from '../../contexts/sign-in/SignInContext';
import { ErrorId } from '../../errors';

enum ProductsSignup {
    bigcommerce_register = 'bigcommerce_register',
    shopify_register = 'shopify_register',
}

const SignInForm = () => {
    const { state, dispatch } = useContext(SignInContext);
    const { product, supportEmail } = useContext(ThemeWrapperContext);
    const [redirectSpinner, setRedirectSpinner] = useState(false);
    const navigate = useNavigate();
    const lostPassword = () => navigate(`../lostpassword?product=${product}`);
    const search = useLocation().search;
    const signUp = () => {
        setRedirectSpinner(true);
        switch (product) {
            case ProductsSignup.bigcommerce_register:
                window.location.href = `/activate/register/${search}`;
                break;
            case ProductsSignup.shopify_register:
                const shop = new URLSearchParams(search).get('shop');
                window.location.href = `/shopify/pre_register?shop=${shop}`;
                break;
            default:
                const next = state.get('next');
                const urlSearchParams = new URLSearchParams(next);
                const nextParams = Object.fromEntries(
                    urlSearchParams.entries()
                );
                const { ecomm_id: ecommId, url } = nextParams;
                if (ecommId) {
                    const apcUser = JSON.parse(
                        sessionStorage.getItem('apc_user')
                    );

                    const { _lastPageUrl: lastPageUrl } = apcUser;

                    const comesFromShopifySignup = lastPageUrl.includes(
                        'welcome/shopify-signup'
                    );

                    if (comesFromShopifySignup) {
                        const params = lastPageUrl.split('?')[1];

                        window.location.href = `/welcome/shopify-signup?${params}`;
                        break;
                    }
                    window.location.href = `/welcome/shopify-signup${search}&url=${url}&ecomm_id=${ecommId}`;
                    break;
                }
                if (product === 'b2b') {
                    window.location.href = `/welcome/signup/${search}&utm_content=rollworks_signup`;
                    break;
                }
                window.location.href = `/welcome/signup/${search}`;
                break;
        }
    };
    const email = state.get('email');
    const password = state.get('password');
    const next = state.get('next');
    const reCaptcha = state.get('reCaptcha');
    const {
        status: submitStatus,
        error: submitError,
        showError: showSubmitError,
        redirect: submitRedirect,
        to: submitRedirectTo,
    } = state.get('submit');

    useEffect(() => {
        if (submitStatus === API_STATUSES.SUCCESS) {
            if (submitRedirect === 'internal_redirect') {
                navigate(`../${submitRedirectTo}`);
            } else if (submitRedirect === 'external_redirect') {
                setRedirectSpinner(true);
                window.location.href = submitRedirectTo;
            }
        } else if (submitStatus === API_STATUSES.ERROR) {
            if (submitError.errorId === ErrorId.user_locked) {
                navigate(`../lockedaccount`);
            }
        }
    }, [state.get('submit')]);

    const setReCaptcha = setReCaptchaFactory(
        dispatch,
        useGoogleReCaptcha().executeRecaptcha
    );
    const setEmail = setEmailFactory(dispatch);
    const setPassword = setPasswordFactory(dispatch);
    const reCaptchaDict = {
        ...reCaptcha,
        executeReCaptcha: setReCaptcha,
    };
    const submit = submitFactory(
        dispatch,
        email.value,
        password.value,
        next,
        reCaptchaDict
    );
    const googleSubmit = googleSubmitFactory(dispatch, next, reCaptchaDict);
    const setNext = setNextFactory(dispatch);
    const { email: urlEmail, next: urlNext } = Object.fromEntries(
        useSearchParams()[0]
    );
    useEffect(() => {
        if (urlEmail) {
            const ev = { target: { value: urlEmail } };
            setEmail(ev);
        }
        if (urlNext) {
            setNext(urlNext);
        }
    }, []);

    const buttonDisabled = !email.valid || !password.valid;
    const onSubmit =
        submitStatus !== API_STATUSES.IN_PROGRESS ? submit : undefined;
    const onFormSubmit = (e: FormEvent) => {
        e.preventDefault();
        onSubmit && onSubmit();
        return false;
    };
    const onGoogleSuccess = (response: CredentialResponse) => {
        submitStatus !== API_STATUSES.IN_PROGRESS && googleSubmit(response);
    };

    if (redirectSpinner) {
        return <FormSpinner />;
    }
    return (
        <div className='entryhall-card-block entryhall-form-sign-in'>
            <form id='sign_in_form' onSubmit={onFormSubmit}>
                <input type='submit' hidden />
                <div className='entryhall-card-header'>
                    <div className='entryhall-card-header-buttons'>
                        <button
                            type='button'
                            id='saml_sign_in_anchor_button'
                            className='btn btn-default entryhall-grey-button'
                            onClick={() => navigate(`../saml${search}`)}
                        >
                            {i18n.gettext('Sign In with Single Sign-On')}
                        </button>
                        {process.env.ENVIRONMENT !== 'production' && (
                            <GoogleButton
                                onSuccess={onGoogleSuccess}
                                onError={() => {
                                    // @FIXME implement error handling
                                    console.log('Error from GoogleButton');
                                }}
                                text='signin_with'
                                width={300}
                            />
                        )}
                        <div className='entryhall-or-separator'>
                            <span>{i18n.gettext('Or')}</span>
                        </div>
                    </div>
                    <div className='entryhall-card-header-error'>
                        {showSubmitError && (
                            <ApiError
                                error={submitError}
                                supportEmailAddress={supportEmail}
                            />
                        )}
                    </div>
                </div>
                <div className='entryhall-card-body'>
                    <InputGroup
                        i18n={i18n}
                        classNames='entryhall-signin-email-input'
                        label={i18n.gettext('Email Address')}
                        inputProps={{
                            autoFocus: true,
                            value: email.value,
                            onChange: setEmail,
                            name: 'username',
                        }}
                        validationState={
                            showSubmitError ? InputGroup.VALIDATION_ERROR : ''
                        }
                    />
                    <PasswordInput
                        i18n={i18n}
                        classNames='entryhall-signin-password-input'
                        label={i18n.gettext('Password')}
                        enableValidation={false}
                        inputProps={{
                            value: password.value,
                            onChange: setPassword,
                            name: 'password',
                        }}
                        validationState={
                            showSubmitError ? InputGroup.VALIDATION_ERROR : ''
                        }
                    />
                </div>
                <div className='entryhall-card-footer'>
                    <button
                        type='button'
                        className='btn btn-primary btn-block'
                        disabled={buttonDisabled}
                        onClick={onSubmit}
                    >
                        {i18n.gettext('Sign In')}
                        <InlineFeedback status={submitStatus} />
                    </button>
                    <a className='forgot-password' onClick={lostPassword}>
                        {i18n.gettext('Forgot your password?')}
                    </a>
                    <section className='extra-info'>
                        <span>{i18n.gettext("Don't have an account?")}</span>
                        <a onClick={signUp}>{i18n.gettext('Sign Up')}</a>
                    </section>
                </div>
            </form>
        </div>
    );
};

export default SignInForm;
